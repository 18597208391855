<div class="axis-wrapper has-fixed-width">
 <cdp-header *ngIf="router.url.match('cdp-lite/claim-overview')"></cdp-header>
  <main
    class="axis-main-container"
    id="content"
    role="main"
  >
    <router-outlet></router-outlet>
  </main>
  <cdp-footer></cdp-footer>
  <axis-scroll2tb></axis-scroll2tb>
</div>


<!-- Modal - Session Time Out -->
<axis-modal
  #sessionTimeoutModal
  data-size="medium"
>
  <axis-modal-header>
    <h1>It looks like you are working on other things</h1>
  </axis-modal-header>
  <axis-modal-content>
    <p> You are still signed in, but your session will expire soon. <br>
      <span class="_text-bold"> You have {{remainingTime}} to continue before you will be signed out. </span>
    </p>
  </axis-modal-content>
  <axis-modal-footer>
    <div class="axis-btn-wrapper">
      <div class="axis-btn-wrapper-left">
      </div>
      <div class="axis-btn-wrapper-right">
        <button
          axis-button
          data-kind="link"
          (click)="sessionTimeoutModal.close();logoutUser();"
        >Sign Out</button>
        <button
          axis-button
          data-kind="primary"
          [disabled]="timeElapsed"
          (click)="continueTimeout();"
        >Continue</button>
      </div>
    </div>
  </axis-modal-footer>
</axis-modal>
