<header axisSticky="1">
  <skip-nav></skip-nav>
    <axis-navbar *ngIf="screenSize!=='mobile'">
      <axis-navbar-logo>
        <a
        href       
      >
        <img
          alt="AIG logo"
          src="assets/images/logos/aig-logo-blue.svg"
        />
        <span class="sr-only">American International Group, Inc.</span>
        </a>
      </axis-navbar-logo>
      <axis-navbar-app-title *ngIf="router.url.match('cdp-lite/claim-overview')"> Digital Claims</axis-navbar-app-title>
      <axis-navbar-app-title *ngIf="router.url.match('cdp-lite/unsupported') || router.url.match('cdp-lite/supported')"> Claims Digital Portal</axis-navbar-app-title>
    </axis-navbar>
    <nav
    class="axis-navbar-mobile"
    *ngIf="screenSize !== 'desktop'"
    [ngClass]="{'open':navbarOpen}"
  >
    <div
      class="axis-mobile-overlay"
      (click)="closeOverlay()"
    ></div>
    <div class="axis-mobile-header">
      <div class="axis-navbar-title">
        <axis-navbar-logo>
          <a
            href           
          >
            <img
              alt="AIG logo"
              src="assets/images/logos/aig-logo-blue.svg"
            />
            <span class="sr-only">American International Group, Inc.</span>
          </a>
        </axis-navbar-logo>
        <axis-navbar-app-title *ngIf="router.url.match('cdp-lite/claim-overview')"> Digital Claims</axis-navbar-app-title>
        <axis-navbar-app-title *ngIf="router.url.match('cdp-lite/unsupported') || router.url.match('cdp-lite/supported')"> Claims Digital Portal</axis-navbar-app-title>
      </div>
      <button
        aria-controls="mobile-nav"
        aria-label="Menu"
        [attr.aria-expanded]="navbarOpen"
        axis-button
        (click)="toggleNavbar()"
        class="kind-icon"
      >
        <axis-icon
          icon="menu"
          font="custom"
        ></axis-icon>
      </button>
    </div>
    <div
      class="axis-navbar-mobile-container"
      id="mobile-nav"
    >
      <ng-template [ngTemplateOutlet]="templateCloseVar"></ng-template>     
    </div>
  </nav>
  <ng-template #templateCloseVar>
    <div class="axis-mobile-close-btn">
      <button
        axis-button
        class="kind-icon"
        (click)="toggleNavbar()"
        aria-label="close"
      >
        <axis-icon
          icon="cross"
          font="custom"
        ></axis-icon>
      </button>
    </div>
  </ng-template>
</header>
